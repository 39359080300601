
window.CardGridSingleCard = (url, target) => {
	return {
		hovered: false,
		cta_link: url,
		cta_target: target,

		clickCta() {
			if (this.cta_target == '_blank') {
				window.open(this.cta_link);
			}
			else {
				window.location = this.cta_link;
			}
		}
	}
}